import { useCallback, useEffect, useMemo, useState } from "react";
import st from "../styles/items/CarouselSlider.module.scss";
import useMobile from "../hooks/useMobile";
import SlotLink from "./SlotLink";
import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";

export default function CarouselSlider({
  items,
  resultCountNumber,
  popularFormat,
}) {
  const isMobile = useMobile({ breakPointThree: 600 });

  const [startIndex, setStartIndex] = useState(0);

  const resultCount = useMemo(() => {
    if (isMobile === "mobile-one") return 6;
    else if (isMobile === "mobile-two") return 5;
    else if (isMobile === "mobile-three") return 4;
    else if (isMobile === "mobile-four") return 3;
    else return resultCountNumber;
  }, [isMobile, resultCountNumber]);

  const maxPages = useMemo(() => {
    const totalItems = items.length;
    const calculatedPages = Math.floor(totalItems / resultCount);
    if (totalItems > resultCount && calculatedPages < 2) {
      return Math.max(calculatedPages, 2);
    }
    return calculatedPages;
  }, [items.length, resultCount]);

  const maxItems = useMemo(() => {
    return resultCount * maxPages;
  }, [resultCount, maxPages]);

  const handleNext = useCallback(() => {
    const newIndex = startIndex + resultCount;
    if (newIndex < maxItems) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(0);
    }
  }, [resultCount, startIndex, maxItems]);

  const handlePrevious = useCallback(() => {
    const newIndex = startIndex - resultCount;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(Math.max(0, maxItems - resultCount));
    }
  }, [resultCount, startIndex, maxItems]);

  useEffect(() => {
    setStartIndex(0);
  }, [isMobile, items]);

  const isFirstPage = startIndex === 0;
  const isLastPage = startIndex + resultCount >= maxItems;

  return (
    <div className={st["item-list-carousel-container"]}>
      <div className={st["next-prev-container"]}>
        <div
          className={`${st["arrow-icon"]} ${isFirstPage ? st["deactive"] : ""}`}
          onClick={isFirstPage ? null : handlePrevious}
        >
          <PrevArrowSquareIcon />
        </div>
        <div
          className={`${st["arrow-icon"]} ${isLastPage ? st["deactive"] : ""}`}
          onClick={isLastPage ? null : handleNext}
        >
          <NextArrowSquareIcon />
        </div>
      </div>

      <div
        className={st["slots-list-container"]}
        style={{
          // 10px = gap
          gridAutoColumns: `calc(100% / ${resultCount} - 10px * ${resultCount - 1} / ${resultCount})`,
        }}
      >
        {items?.slice(startIndex, startIndex + resultCount).map((item, i) => (
          <SlotLink
            key={item.id}
            index={i}
            popularFormat={popularFormat}
            {...item}
          />
        ))}
      </div>
    </div>
  );
}
